<template>
  <div>
    <TableLight>
      <thead>
        <tr>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.date') }}</th>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.reviewerName') }}</th>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.reviewerCompany') }}</th>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.reviewerPosition') }}</th>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.recommendationScore') }}</th>
          <th class="py-4 text-start">{{ t('Reviews.product.view.table.status') }}</th>
          <th class="py-4 text-start"></th>
          <th class="py-4 text-start"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="surveyResponse in surveyResponses"
          :key="surveyResponse.id"
          :class="{
            'cursor-pointer': isOpenable(surveyResponse.aasmState),
          }"
          @click="openDetails(surveyResponse)"
        >
          <td class="text-left">
            <span>{{ formatDate(surveyResponse.updatedAt) }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerName || '---' }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerCompany || '---' }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerPosition || '---' }}</span>
          </td>
          <td class="text-left">
            <div class="flex items-center">
              <RatingStars :value="ratingValue(surveyResponse)" />
              <span class="ml-1">{{ ratingValueText(surveyResponse) }}</span>
            </div>
          </td>
          <td class="text-left">
            <SurveyResponseStatusBadge :survey-response="surveyResponse" />
          </td>
          <td class="text-left">
            <ReviewInteractionStatus
              v-if="surveyResponse.reminders.length > 0 && surveyResponse.aasmState !== SurveyResponseStateEnum.Published"
              :type="ReviewInteractionType.REMINDER"
              :tooltip-text="t('Reviews.product.review.reminderStatusHint', { count: surveyResponse.reminders.length })"
            />
            <ReviewInteractionStatus v-if="hasReviewReply(surveyResponse)" :type="ReviewInteractionType.REPLY" />
          </td>
          <td class="text-right">
            <MdiSvg v-if="isOpenable(surveyResponse.aasmState)" class="mr-2" :path="mdiEye" />
          </td>
        </tr>
      </tbody>
    </TableLight>
    <div v-if="loading" class="flex justify-center mt-6">
      <CircleSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CircleSpinner, MdiSvg, RatingStars } from '@ramp106/omrjs-core-ui'
import { mdiEye } from '@mdi/js'
import TableLight from '@/ui/TableLight.vue'

import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { SurveyResponseStateEnum } from '@/gql/reviews'
import SurveyResponseStatusBadge from './SurveyResponseStatusBadge.vue'
import { ReviewsRouteName } from '@/router/types'
import ReviewInteractionStatus from '@/components/ReviewsManagement/Reviews/ReviewInteractionStatus.vue'
import { ReviewInteractionType, type SurveyResponse } from '@/components/ReviewsManagement/Reviews/types'

defineProps<{
  productId: string
  surveyResponses: SurveyResponse[]
  loading?: boolean
}>()

const { d, t } = useI18n()
const router = useRouter()

function hasReviewReply(surveyResponse: SurveyResponse) {
  return !!surveyResponse.review?.reviewReply
}

function isOpenable(status: string) {
  return [
    SurveyResponseStateEnum.Published,
    SurveyResponseStateEnum.Rejected,
    SurveyResponseStateEnum.Drafted,
    SurveyResponseStateEnum.Stale,
  ].includes(status as SurveyResponseStateEnum)
}

function formatDate(date?: string | null) {
  if (!date) {
    return '-'
  }

  return d(date, 'ddmmyy')
}

function ratingValue(surveyResponse: SurveyResponse) {
  const score = surveyResponse.recommendationScore
  if (typeof score !== 'number') {
    return undefined
  }
  return score / 2
}

function ratingValueText(surveyResponse: SurveyResponse) {
  return ratingValue(surveyResponse)?.toFixed(1) || '-'
}

function openDetails(surveyResponse: SurveyResponse) {
  if (!isOpenable(surveyResponse.aasmState)) {
    return
  }
  router.push({ name: ReviewsRouteName.ReviewsProductViewReviewDetails, params: { surveyResponseId: surveyResponse.id } })
}
</script>
